import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import GravityFormForm from "gatsby-plugin-gravity-forms"
import "./form.css"
import {
    Box,
    Container,
    Flex,
    Heading,
    Kicker,
    Section,
    Subhead,
    Text,
  } from "../components/ui"
  import { StaticImage } from "gatsby-plugin-image"
  import ButtonBook from "../components/buttonbook"

const Contact = () => {
    // Use useStaticQuery to get the form data for form 1.
    // There is no need to import the fragment ...GravityFormFields as this is
    // imported globally by the gatsby-plugin-gravity-forms plugin.
    const data = useStaticQuery(graphql`
      query formQuery {
        wp {
            ...GravityFormSettings
          }
          wpGfForm(databaseId: { eq: 1 }) {
            ...GravityFormFields
          }
        }
    `)
  
    // Then pass the data into the form component.
    return (
      <>
        <GatsbySeo
                    title='Michael Law | Contact Me'
                    description='Living In Whanganui East, Whanganui.'
                  />
      <Layout>
      <Section>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box width="75%">
          <Heading as="h1">
              Contact Me
            </Heading>
          
          <StaticImage
      style={{
        gridArea: "1/1",
        // You can set a maximum height for the image, if you wish.
        maxHeight: 400,
      }}
      src="https://mlaw.surgetechnology.co.nz/wp-content/uploads/sites/8/2022/07/a32d416c-2164-43f5-9641-15a56da82ca6.jpg"
      alt="Whanganui"
      placeholder="blurred"
      layout="fullWidth"
      
      formats={["auto", "webp", "avif", "png"]}
      
      
    />
          
          <Text as="p">I live in Whanganui East and will spend most of my time campaigning locally, I also work in central and can be found at 1/30 Drews Avenue.</Text>
          
            
          </Box>
          <Box width="half">
          
          <GravityFormForm data={data} />
           
          </Box>
        </Flex>
      </Container>
    </Section>   
      
        </Layout>
      </>
    )
  }
  
  export default Contact